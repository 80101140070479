<template lang="pug">
div(
    style="position: relative"
)
    IonButton(
        @click="goBack"
        style="--border-radius: 8px 0 0 8px; position: absolute; right: 0; top: -27px"
        color="secondary"
        size="large"
    )
        IonIcon(
            :icon="chevronBackCircle"
            color="white"
        )

div(
    style="background-color: var(--ion-color-primary); min-height: 59px"
    class="shadow pb-4 d-flex align-center"
)
    Container(
        class="d-flex align-center"
        style="padding-right: 70px"
    )
        slot
</template>

<script>
import { chevronBackCircle } from "ionicons/icons"
import { useRouter } from "vue-router"
import { getCurrentInstance } from "vue"
import { useUtilities } from "@/composables/utilities"
export default {
    setup() {
        chevronBackCircle

        const router = useRouter()

        // bus
        const emitter = useUtilities().getEmitter()

        function goBack() {
            router.go(-1)
            // emitter.emit("scroll-top")
        }

        return { chevronBackCircle, goBack }
    }
}
</script>