<template lang="pug">
div
    Spacer(
        small
    )
    div(
        :style="light ? { 'background-color': 'var(--ion-color-quaternary)' } : {}"
    )
        Container
            div(class="d-flex align-center")
                div(
                    style="width: 45%"
                )
                    IonText(
                        :color="light ? 'primary' : 'tertiary'"
                    )
                        h4 Aufgabe
                div(
                    style="width: 35%"
                )
                    IonText(
                        :color="light ? 'primary' : 'tertiary'"
                    )
                        h4 Menge
                div(
                    style="width: 20%"
                    v-if="priceTags"
                )
                    IonText(
                        :color="light ? 'primary' : 'tertiary'"
                    )
                        h4 Preise

        CustomDivider(
            style="background-color: var(--ion-color-accent)"
        )

    div(
        v-for="(line, index) in order.lines"
        :key="index"
    )
        Container
            div(
                class="d-flex align-center pt-3 pb-3 "
                style="min-height: 73px"
            )
                div(
                    style="width: 45%"
                    class="pr-2"
                )
                    IonText(
                        :color="light ? 'primary' : 'white'"
                        style="font-weight: 700"
                    )
                        div {{ line.positiontext }}
                div(
                    style="width: 35%"
                    class="pr-2"
                )
                    IonText(
                        :color="light ? 'primary' : 'white'"
                        style="font-weight: 700"
                    )
                        span {{ line.quantity }}
                        span(
                            v-if="!!line.uom"
                            class="ml-1"
                        ) {{ line.uom }}

                //- edit buttons column
                div(
                    style="width: 20%"
                    v-if="!noEditButtons"
                )
                    div(
                        class="d-flex justify-end"
                        style="width: 100%"
                    )
                        IonButton(
                            class="round-btn"
                            :color="readonly ? 'accent' : 'secondary'"
                            :disabled="readonly"
                            style="width: 30px; height: 30px"
                            @click="openLineEditModal(line)"
                        )
                            CustomIcon(
                                fileName="edit.png"
                            )

                //- price column
                div(
                    style="width: 20%"
                    v-if="priceTags"
                )
                    IonText(
                        :color="light ? 'primary' : 'white'"
                        style="font-weight: 700"
                    )
                        div € {{ line.linesum.toFixed(2) }}
    //-     Container
    //-         IonRow
    //-             IonCol(
    //-                 size="6"
    //-             )
    //-                 IonText(
    //-                     :color="light ? 'primary' : 'tertiary'"
    //-                 )
    //-                     h4 Aufgabe
    //-             IonCol(
    //-                 size="4"
    //-             )
    //-                 IonText(
    //-                     :color="light ? 'primary' : 'tertiary'"
    //-                 )
    //-                     h4 Menge
    //-             //- IonCol(
    //-             //-     size="2"
    //-             //-     v-if="!noEditButtons"
    //-             //- )
    //-             IonCol(
    //-                 size="2"
    //-                 v-if="priceTags"
    //-             )
    //-                 IonText(
    //-                     :color="light ? 'primary' : 'tertiary'"
    //-                 )
    //-                     h4 Preise

    //-     CustomDivider(
    //-         style="background-color: var(--ion-color-accent)"
    //-     )

    //- div(
    //-     v-for="(line, index) in order.lines"
    //-     :key="index"
    //- )
    //-     Container
    //-         IonRow(
    //-             class="pt-3 pb-3 align-center"
    //-             style="min-height: 73px"
    //-         )
    //-             IonCol(
    //-                 size="6"
    //-                 style="padding-right: 15px"
    //-             )
    //-                 IonText(
    //-                     :color="light ? 'primary' : 'white'"
    //-                     style="font-weight: 700"
    //-                 )
    //-                     div {{ line.positiontext }}
    //-             IonCol(
    //-                 size="4"
    //-             )
    //-                 IonText(
    //-                     :color="light ? 'primary' : 'white'"
    //-                     style="font-weight: 700"
    //-                 )
    //-                     span {{ line.quantity }}
    //-                     span(
    //-                         v-if="!!line.uom"
    //-                         class="ml-1"
    //-                     ) {{ line.uom }}

    //-             //- edit buttons column
    //-             IonCol(
    //-                 size="2"
    //-                 v-if="!noEditButtons"
    //-             )
    //-                 div(
    //-                     class="d-flex justify-end"
    //-                     style="width: 100%"
    //-                 )
    //-                     IonButton(
    //-                         class="round-btn"
    //-                         :color="readonly ? 'accent' : 'secondary'"
    //-                         :disabled="readonly"
    //-                         style="width: 30px; height: 30px"
    //-                         @click="openLineEditModal(line)"
    //-                     )
    //-                         CustomIcon(
    //-                             fileName="edit.png"
    //-                         )

    //-             //- price column
    //-             IonCol(
    //-                 size="2"
    //-                 v-if="priceTags"
    //-             )
    //-                 IonText(
    //-                     :color="light ? 'primary' : 'white'"
    //-                     style="font-weight: 700"
    //-                 )
    //-                     div € {{ line.linesum.toFixed(2) }}
        CustomDivider(
            style="background-color: var(--ion-color-accent)"
            v-if="index != order.lines.length - 1"
        )
div(
    class="overflow-hidden radius-bottom"
    v-if="!noAddButton"
)
    IonButton(
        :color="readonly ? 'accent' : 'secondary'"
        size="large"
        expand="full"
        fill="solid"
        height="100%"
        class="mt-0 mb-0"
        :disabled="readonly"
        @click="openNewLineModal()"
    )
        CustomIcon(
            fileName="edit.png"
        )
        IonText(
            color="primary"
        )
            h3 Neue position hinzufügen

CustomModal(
    v-model="lineEditModal"
)
    LineEditModalContent(
        :line="lineToEdit"
        @confirm="(a) => saveLineChanges(a)"
        @remove="(a) => removeLine(a)"
    )

CustomModal(
    v-model="newLineModal"
)
    NewLineModalContent(
        @confirm="(a) => createNewLine(a)"
        @decline="closeNewLineModal"
    )
</template>

<script>
import CustomDivider from "@/components/molecules/CustomDivider"
import CustomIcon from "@/components/molecules/CustomIcon"
import CustomModal from "@/components/molecules/modals/CustomModal"
import LineEditModalContent from "@/components/molecules/modals/LineEditModalContent"
import NewLineModalContent from "@/components/molecules/modals/NewLineModalContent"
import { ref } from "vue"
import { useUtilities } from "@/composables/utilities"
import { useState } from "@/composables/state"
export default {
    components: {
        CustomDivider,
        CustomIcon,
        LineEditModalContent,
        CustomModal,
        NewLineModalContent
    },

    props: {
        order: {
            type: Object,
            required: true
        },
        readonly: {
            type: Boolean
        },
        light: {
            type: Boolean
        },
        noAddButton: {
            type: Boolean
        },
        noEditButtons: {
            type: Boolean
        },
        priceTags: {
            type: Boolean
        }
    },

    setup(props) {
        const { updateOrder } = useState()

        // bus
        const emitter = useUtilities().getEmitter()

        // line edit
        const lineEditModal = ref(false)
        const lineToEdit = ref(null)

        function openLineEditModal(line) {
            lineToEdit.value = line
            lineEditModal.value = true
        }

        function saveLineChanges(editedLine) {
            lineEditModal.value = false
            lineToEdit.value = null

            let orderCopy = JSON.parse(JSON.stringify(props.order))

            orderCopy.lines = orderCopy.lines.map((a) => {
                if (a.id == editedLine.id) return editedLine
                else return a
            })

            updateOrder(orderCopy)

            emitter.emit("setLoadingModal", true)
            setTimeout(() => {
                emitter.emit("setLoadingModal", false)
            }, 1000)
            setTimeout(() => {
                emitter.emit("showSuccessModal")
            }, 1000)
        }

        function removeLine(editedLine) {
            lineEditModal.value = false
            lineToEdit.value = null

            let orderCopy = JSON.parse(JSON.stringify(props.order))

            orderCopy.lines = orderCopy.lines.filter(
                (a) => a.id != editedLine.id
            )

            updateOrder(orderCopy)

            emitter.emit("setLoadingModal", true)
            setTimeout(() => {
                emitter.emit("setLoadingModal", false)
            }, 1000)
            setTimeout(() => {
                emitter.emit("showSuccessModal")
            }, 1000)
        }

        // new line

        const newLineModal = ref(false)

        function openNewLineModal() {
            newLineModal.value = true
        }
        function closeNewLineModal() {
            newLineModal.value = false
        }

        function createNewLine(newLine) {
            newLineModal.value = false

            let orderCopy = JSON.parse(JSON.stringify(props.order))

            orderCopy.lines.push(newLine)

            updateOrder(orderCopy)

            emitter.emit("setLoadingModal", true)
            setTimeout(() => {
                emitter.emit("setLoadingModal", false)
            }, 1000)
            setTimeout(() => {
                emitter.emit("showSuccessModal")
            }, 1000)
        }

        return {
            lineEditModal,
            openLineEditModal,
            lineToEdit,
            saveLineChanges,
            removeLine,
            newLineModal,
            openNewLineModal,
            closeNewLineModal,
            createNewLine
        }
    }
}
</script>