<template lang="pug">
IonCard(
    color="accent"
    class="pb-5 pt-5 pl-5 pr-5"
    style="width: 100%; border: 3px solid var(--ion-color-primary)"
)
    //- name
    div(
        style="position: relative"
    )
        IonItem(
            class="radius mb-3"
            lines="none"
        )
            IonLabel(
                position="floating"
                style="--color: var(--ion-color-primary)"
            ) Name
            IonInput(
                v-model="newLine.positiontext"
                style="--color: var(--ion-color-primary)"
                mode="ios"
                required
            )

        select(
            style="position: absolute; top: 0; left: 0; height: 100%; width: 100%; z-index: 2; opacity: 0"
            v-model="newLine.positiontext"
        )
            option(
                value="Name from API 1"
            ) Name from API 1
            option(
                value="Name from API 2"
            ) Name from API 2

    //- amount
    IonItem(
        class="radius mb-3"
        lines="none"
    )
        IonLabel(
            position="floating"
            style="--color: var(--ion-color-primary)"
        ) Menge
        IonInput(
            v-model="newLine.quantity"
            type="number"
            style="--color: var(--ion-color-primary)"
            mode="ios"
            required
        )

    //- unit

    div(
        style="position: relative"
    )
        IonItem(
            class="radius mb-3"
            lines="none"
        )
            IonLabel(
                position="floating"
                style="--color: var(--ion-color-primary)"
            ) Maßeinheit
            IonInput(
                v-model="newLine.uom"
                style="--color: var(--ion-color-primary)"
                mode="ios"
                required
            )

        select(
            style="position: absolute; top: 0; left: 0; height: 100%; width: 100%; z-index: 2; opacity: 0"
            v-model="newLine.uom"
        )
            option(
                value="Unit from API 1"
            ) Unit from API 1
            option(
                value="Unit from API 2"
            ) Unit from API 2

    //- buttons
    IonRow(class="receding mt-4")
        //- remove button
        IonCol(
            size="6"
        )
            IonButton(
                @click="$emit('decline'); resetLineObject()"
                color="primary"
                size="large"
                expand="block"
            )
                IonText(
                    color="white"
                )
                    h3 Abbruch

        //- save button
        IonCol(
            size="6"
        )
            IonButton(
                @click="$emit('confirm', newLine); resetLineObject()"
                color="secondary"
                size="large"
                expand="block"
            )
                IonText(
                    color="primary"
                )
                    h3 Speichern
</template>

<script>
import { ref, onCreate } from "vue"

export default {
    setup() {
        const newLine = ref()

        let emptyLineObject = {
            positiontext: null,
            quantity: null,
            uom: null
        }
        function resetLineObject() {
            newLine.value = JSON.parse(JSON.stringify(emptyLineObject))
        }

        resetLineObject()

        return { newLine, resetLineObject }
    }
}
</script>