<template lang="pug">
IonCard(
    color="accent"
    class="pb-5 pt-5 pl-5 pr-5"
    style="width: 100%; border: 3px solid var(--ion-color-primary)"
    v-if="!!lineCopy"
)
    h2(class="text-center mb-6") {{ lineCopy.positiontext }}
    IonItem(
        class="radius"
        lines="none"
    )
        IonLabel(
            position="floating"
            style="--color: var(--ion-color-primary)"
        )
            span Menge
            span(
                v-if="!!lineCopy.uom"
                class="ml-1"
            ) ({{ lineCopy.uom }})
        IonInput(
            v-model="lineCopy.quantity"
            type="number"
            style="--color: var(--ion-color-primary)"
            mode="ios"
            required
        )

    //- buttons
    IonRow(class="receding mt-4")
        //- remove button
        IonCol(
            size="6"
        )
            IonButton(
                @click="$emit('remove', lineCopy)"
                color="danger"
                size="large"
                expand="block"
            )
                IonText(
                    color="white"
                )
                    h3 Entfernen

                IonIcon(
                    :icon="trashOutline"
                    color="white"
                    style="font-size: 30px"
                    slot="start"
                )

        //- save button
        IonCol(
            size="6"
        )
            IonButton(
                @click="$emit('confirm', lineCopy)"
                color="secondary"
                size="large"
                expand="block"
            )
                IonText(
                    color="primary"
                )
                    h3 Speichern
</template>

<script>
import { watch, ref } from "vue"
import { trashOutline } from "ionicons/icons"
export default {
    props: {
        line: Object
    },
    setup(props) {
        const lineCopy = ref(null)

        trashOutline

        watch(
            () => props.line,
            (val) => {
                if (!!val) {
                    lineCopy.value = JSON.parse(JSON.stringify(val))
                } else {
                    lineCopy.value = null
                }
            },
            { immediate: true }
        )

        return { lineCopy, trashOutline }
    }
}
</script>